<template>
  <div class="aboutUs-container">
    <header-nav></header-nav>
    <div class="main">
      <div class="main-header">
        <!-- <div class="header-content">
          <img
            src="@/assets/images/about/aboutus_banner_icon.png"
            alt
            width="99px"
          />
          <p class="title" style="color: #ffffff">关于我们</p>
        </div> -->
      </div>
      <div class="certificate">
        <!-- <Row type="flex" justify="space-between" class="code-row-bg">
          <Col span="8" class="certificateContainer" v-for="item in technical" :key="item.id">
            <img
              :src="item.contentUrl"
              alt=""
              width="300px"
              height="220px"
            />
            <div class="technicalIntroduce">
              <p>{{item.littleTitle}}</p>
              <p>{{item.title}}</p>
              <p>{{item.content}}</p>
            </div>
          </Col>
    
      
        </Row> -->

        <div
          class="certificateContainer"
          v-for="item in technical"
          :key="item.id"
        >
          <div>
            <img :src="item.contentUrl" alt="" class="supportImg" />
            <div class="technicalIntroduce">
              <p>{{ item.littleTitle }}</p>
              <p>{{ item.title }}</p>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-nav></footer-nav>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>

<script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
import { getTechnicalList } from "@/api/newWebsite";
export default {
  name: "technical-support",
  components: { headerNav, footerNav },
  metaInfo: {
    title:
      "新巢天诚-技术支持 智慧通行|人脸识别终端|人行通道闸机|CAT.1智能门锁|LORA智能门锁|UNB联网锁|NB人脸智能门锁|WiFi无线物联网锁|人才公寓管理系统|智慧公寓管理系统",
    meta: [
      {
        name: "keywords",
        content:
          "天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
      },
      {
        name: "description",
        content:
          "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
      },
    ],
  },
  data() {
    return {
      technical: [],
    };
  },
  methods: {
    getTechnicalList() {
      getTechnicalList({
        pageNum: 1,
        pageSize: 100,
      })
        .then((res) => {
          if (res.code == 0) {
            this.technical = res.data.list;
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取数据失败,请稍后再试");
        });
    },
  },
  created() {
    this.getTechnicalList();
  },
  mounted() {},
};
</script>

<style scoped>
.aboutUs-container {
  /* min-width: 1366px; */
}
.main {
  width: 100%;
  padding-top: 80px;
}
.main-header {
  width: 100%;
  height: 386px;
  text-align: center;
  background-image: url("../assets/images/technical/jishu.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.header-content {
  height: 160px;
  margin: auto;
  padding-top: 120px;
  padding-left: 20px;
}
.title {
  font-size: 40px;
  margin-top: 30px;
  font-weight: bold;
}
.line {
  width: 80px;
  margin: auto;
  border-bottom: 2px solid #d34100;
  margin-top: 10px;
}
.content {
  font-size: 15px;
  padding-top: 20px;
  color: #333333;
  margin: auto;
  text-align: left;
  letter-spacing: 2px;
  line-height: 25px;
}
.certificate {
  max-width: 1200px;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.certificateContainer {
  width: 30%;
  min-height: 220px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 6.44px 2.56px rgb(232 235 234 / 39%);
}
.technicalIntroduce {
  width: 100%;
  height: 150px;
  background-color: #2b6288;
  margin-top: -7px;
  padding-top: 30px;
  padding-left: 30px;
  text-align: left;
  color: #a9d8f8;
}
.technicalIntroduce p:nth-child(2) {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.technicalIntroduce p:nth-child(3) {
  margin-top: 10px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.supportImg {
  width: 100%;
  height: 220px;
}
@media screen and (max-width: 768px) {
  .main {
    padding-top: 50px;
  }
  .main-header {
    height: 166px;
  }
  .certificateContainer {
    width: 80%;
    margin: auto;
  }
}
</style>